import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            siteDomain
            siteUrl
            title
            titleTemplate
            locale
            description
            publisher
            publisher_salutation
            jobtitle
            organization {
              name
              dateCompanyFormed
              description
              url
              logo
              street
              postcode
              city
              phone
              fax
              email
            }
            localBusinessSchema {
              type
              id
              name
              description
              image              
              url
              logo
              telephone
              priceRange
              address {                
                streetAddress
                addressLocality
                addressRegion
                postalCode
                addressCountry
              },
              geo {
                latitude
                longitude
              }
              openingHoursSpecification {
                dayOfWeek
                opens
                closes
              },
              sameAs
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata