import React from "react"
import useSiteMetadata from "../components/Data/useSiteMetadata"

export default function ErrorPage() {
  const { title } = useSiteMetadata()

  return (
    <div>
    <div>{title}</div>
    <div>404 Not Found</div>
    </div>
  ) 
}
